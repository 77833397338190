// There is no OOB solution to adjust columns width. So Changing attribute width columns by setting max-width property.
// But no guarantee it'll work in future Polaris release.
._colspan-4_14yj0_wvck3_59:nth-child(1) {
  flex: 0 0 40% !important;
  max-width: 30% !important;
}

._colspan-4_14yj0_wvck3_59:nth-child(2) {
  flex: 0 0 40% !important;
  max-width: 30% !important;
}

._colspan-4_14yj0_wvck3_59:nth-child(3) {
  flex: 0 0 50% !important;
  max-width: 40% !important;
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  order: 0;
  flex-grow: 10;
  margin-right: 2rem;
}

.input-filter {
  order: 0;
  flex-grow: 1;
  flex-shrink: 1;
  width: auto;
  max-width: 728px;
  margin-right: 1rem;
}

.date-range-filter {
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
}

.filtering-results {
  margin-left: 1rem;
  line-height: 3rem;
}