@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;
// @use './header';
// @use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;
@import '~@amzn/awsui-components-react/sass/constants';


// https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/
.custom-visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.awsui-polaris-dark-mode {
  .awsui-table-header,
  .awsui-cards-header {
    color: awsui.$color-text-heading-default;
  }
}


#b {
  // accommodate the space for the fixed header
  padding-top: 38px;
}

#h {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  // corresponds to the z-index in Mezzanine
  z-index: 1000;
  margin: 0;
  padding: 0 30px;
  background: awsui.$color-background-home-header;
}

// temporary fix for this issue: https://issues.amazon.com/issues/AWSUI-10091
// it is needed for 2.1 components and should be removed after we migrate Modal and TablePreferences
.awsui-modal-open .awsui {
  z-index: 2000;
}

#recharts-custom-tooltip, .recharts-tooltip-wrapper {
  pointer-events: all;
}

// needs to be more specific than `.awsui ul` style
.awsui ul.menu-list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  & > li {
    padding: 0 4px;
    margin: 13px 0 9px;

    a, div {
      float: left;
      color: $awsui-white;
      line-height: 16px;
    }

    a, a:hover {
      cursor: pointer;
      text-decoration: none;
    }

    &.title {
      font-weight: bold;
    }

    &.selected > a {
      font-weight: bold;
      color: $awsui-amazon-orange;
    }

    &.separated {
      border-left: 1px solid awsui.$color-border-control-default;
      padding-left: 9px;
      margin-left: 9px;
    }

    &.ml-auto {
      margin-left: auto;
    }
  }
}

.awsui-polaris-dark-mode {
  .awsui-app-layout {
    h2,
    h3,
    .awsui-util-container-header {
      color: awsui.$color-text-heading-default;
    }
  }
}

.awsui-date-time-range-container {
  display: inline-block !important;
}

.custom-home__header {
  background: #002e36 url('../images/landing-page-bg-vectors.png') no-repeat fixed !important;
}
