div.section {
    &:first-of-type {
        margin-top: 30px;
    }
    margin-bottom: 30px;
    opacity: 1;

    &.hidden {
        opacity: 0;
    }

    &:not(.hidden) {
        animation-name: fadeIn;
        animation-duration: 1s;
    }
}

.foo { flex: 1; }

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.float-right {
    float: right;
}

.custom-toast *{
    font-size: 1.4rem;
    line-height: 2rem;
    font-family: "Amazon Ember", Helvetica, Arial, sans-serif;
    word-break: break-all;
    color: white;
}

.input-container-table {
    display: flex;
    flex-wrap: wrap;
    order: 0;
    flex-grow: 10;
    margin-right: 2rem;
}
.right-border {
    border-right: 1px dotted black;
    height: 1500px;
}