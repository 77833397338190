.bubble-inline-edit:hover .edit-icon {
    display: inline;
    cursor: pointer;
    transition: .5s ease;
    opacity: 2;
}

.bubble-inline-edit:hover {
    white-space: normal;
}

.bubble-inline-edit:hover .bubble-inline-edit-overlay {
    opacity: 1;
    display: inline;
}

.edit-icon {
    display: none;
}

a[aria-controls$="RELATIVE-panel"]  {
    display: none !important;
}

.bubble-inline-edit-overlay {
    transition: .5s ease;
    opacity:0;
}

/*
Remove this once TimeRange component from execution view is replaced with DatePicker from polaris library
*/
#execution-view-table {
    z-index: 0;
    position: relative;
}
